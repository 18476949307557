import {FC} from "react";

import classes from './Footer.module.css'

const Footer: FC = () => {
    return (
        <footer className={classes.footer}>
            <div className='container'>ICF “UA.GROMADA”</div>
        </footer>
    )
}

export default Footer;
