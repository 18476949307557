import {FC} from "react";

import classes from './RequisitesPage.module.css'

const RequisitesPage: FC = () => {
    return (
        <main className='container'>
            <h2 className={classes.title}>REKVIZITAI</h2>
            <section className={classes.section}>
                <div>
                    <h4>Banko sąskaitos</h4>
                    <ol>
                        <li>EUR: LT497189900009700848 (AB Šiaulių bankas)</li>
                    </ol>
                </div>
                <div>
                    <h4>Kriptovaliutų piniginės</h4>
                    <ol>
                        <li>BTC: bc1qgu2dy2p6f93hl0yuxs64zpyq4l0l79ylf4r8af</li>
                        <li>ETH: 0xbc9471f8C0AC14aE681F2d79178e1eE02CD3fF7a</li>
                        <li>USDT ECR20: 0xbc9471f8C0AC14aE681F2d79178e1eE02CD3fF7a</li>
                        <li>USDT TRX20: TBgq194E91K7t1h5htkTGqA8oeJvBqX8MC</li>
                    </ol>
                </div>
                <div>
                    <h4>Fondo juridiniai rekvizitai</h4>
                    <ol>
                        <li>
                            <span>OPF:</span>
                            <span>Labdaros organizacija</span>
                        </li>
                        <li>
                            <span>Pavadinimas:</span>
                            <span>UA GROMADA</span>
                        </li>
                        <li>
                            <span>Įmonės kodas:</span>
                            <span>306145977</span>
                        </li>
                        <li>
                            <span>Juridinis adresas:</span>
                            <span>Švitrigailos g. 11K-109, LT-03228 Vilnius</span>
                        </li>
                        <li>
                            <span>Direktorius:</span>
                            <span>Andrius Misiukas</span>
                        </li>
                    </ol>
                </div>
            </section>
        </main>
    )
}

export default RequisitesPage;
