import {FC} from "react";
import cn from 'classnames';

import closeIcon from './img/close.svg';

import classes from './Drawer.module.css'
import {NavLink} from "react-router-dom";

interface IDrawer {
    show: boolean,
    onClose: (show: boolean) => void
}

const Drawer: FC<IDrawer> = ({ show, onClose }) => {
    const handleClose = () => {
        onClose(false);
    }

    return (
        <div className={cn(classes.backdrop, show ? classes.backdropShow : '')} onClick={handleClose}>
            <div className={cn(classes.drawer, show ? classes.drawerShow : '')} onClick={(e) => e.stopPropagation()}>
                <div className={classes.drawerHeader}>
                    <img src={closeIcon} alt="" width='30' onClick={handleClose} />
                </div>
                <ul className={classes.drawerContent} onClick={handleClose}>
                    <li>
                        <NavLink to='/'>APIE FONDĄ</NavLink>
                    </li>
                    <li>
                        <NavLink to='/partners'>PARTNERIAI</NavLink>
                    </li>
                    <li>
                        <NavLink to='/reporting'>ATSKAITOMYBĖ</NavLink>
                    </li>
                    <li>
                        <NavLink to='/requisites'>REKVIZITAI</NavLink>
                    </li>
                    <li>
                        <NavLink to='/contacts'>KONTAKTAI</NavLink>
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default Drawer;
