import {FC, useState} from "react";
import {Link, NavLink} from "react-router-dom";
import cn from 'classnames';

import logo from './img/logo.svg'
import menu from './img/menu.svg'

import classes from './Header.module.css'
import Drawer from "../Drawer";


const Header: FC = () => {
    const [showDrawer, setShowDrawer] = useState(false);
    return (
        <>
            <header className={classes.header}>
                <div className={cn('container', classes.headerTop)}>
                    <Link to='/'>
                        <img src={logo} alt="Logo" className={classes.logo}/>
                    </Link>
                    <div className={classes.buttons}>
                        <Link to='/requisites'>Prisidėti įnašu</Link>
                        <a href='https://20.gifts/need-help'>Prašyti pagalbos</a>
                    </div>

                    <button className={classes.menu} onClick={() => setShowDrawer(true)}>
                        <img src={menu} alt=""/>
                    </button>
                </div>
                <nav className={classes.navWrapper}>
                    <ul className={cn('container', classes.nav)}>
                        <li>
                            <NavLink to='/'>APIE FONDĄ</NavLink>
                        </li>
                        <li>
                            <NavLink to='/partners'>PARTNERIAI</NavLink>
                        </li>
                        <li>
                            <NavLink to='/reporting'>ATSKAITOMYBĖ</NavLink>
                        </li>
                        <li>
                            <NavLink to='/requisites'>REKVIZITAI</NavLink>
                        </li>
                        <li>
                            <NavLink to='/contacts'>KONTAKTAI</NavLink>
                        </li>
                    </ul>
                </nav>
            </header>
            <Drawer show={showDrawer} onClose={setShowDrawer}/>
        </>
    )
}

export default Header;
