import {FC} from "react";

import classes from './ContactsPage.module.css'

const ContactsPage: FC = () => {
    return (
        <main className='container'>
            <h4 className={classes.title}>KONTAKTAI</h4>
            <section className={classes.section}>
                <div className={classes.contacts}>
                    <div>
                        <h4>Fondo priėmimo biuras:</h4>
                        <a href="tel:+37065215018">+370 652 15018</a>
                        <a href="mailto:info@uagromada.com">info@uagromada.org</a>
                    </div>
                    <div>
                        <h4>Fondo kanceliarija:</h4>
                        <a href="https://goo.gl/maps/KAmCQjtQYdMZiv7c7">Švitrigailos g. 11K-109, LT-03228 Vilnius</a>
                    </div>
                </div>
                {/*<ContactForm />*/}
            </section>
        </main>
    )
}

export default ContactsPage;
