import {FC} from "react";

import classes from './ReportingPage.module.css'

const ReportingPage: FC = () => {
    return (
        <main className='container'>
            <h2 className={classes.title}>ATSKAITOMYBĖ</h2>
            <section className={classes.section}>
                <h4>2022 m. ataskaita</h4>
                <p>Tikimasi, kad visa 2022 m. ataskaita bus pateikta svetainėje iki 2023 m. vasario 23 d.</p>
                <div>
                    <p>Nuotraukų ir vaizdo įrašų ataskaitas galima peržiūrėti pagal šią nuorodą: <span>nuoroda</span></p>
                </div>
            </section>
        </main>
    )
}

export default ReportingPage
