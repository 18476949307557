import {FC} from "react";
import cn from 'classnames'

import Slider from "../../components/Slider";

import icon from './img/check_circle.svg'

import classes from './HomePage.module.css'

const HomePage: FC = () => {
    return (
        <main>
            <Slider />
            <section className={cn(classes.section, classes.sectionTitle)}>
                <div className='container'>
                    <p className={classes.center}>Tarptautinis labdaros fondas „UKRAINSKA GROMADA“ yra nevalstybinė savanoriška labdaros organizacija, sukurta kaip labdaros fondas, įkurta ir veikianti pagal Ukrainos Konstituciją, Ukrainos įstatymą „Dėl labdaros veiklos ir labdaros organizacijų“, galiojančius Ukrainos teisės aktus ir šių Įstatymų nuostatas bei nesiekia gauti pelno vykdydama savo veiklą.</p>
                </div>
            </section>
            <section className={cn(classes.section, classes.sectionBGBlue, classes.sectionGoals)}>
                <div className='container'>
                    <p className={classes.center}><b>Tarptautinio labdaros fondo „UKRAINSKA GROMADA“ pagrindinis labdaringos veiklos tikslas</b> – teikti labdaringą pagalbą nukentėjusiems nuo katastrofų, ginkluotų konfliktų ir nelaimingų atsitikimų, taip pat pabėgėliams ir asmenims, patekusiems į sunkias gyvenimo aplinkybes.</p>
                    <div>
                        <h4>Pagrindiniai fondo veiklos tikslai:</h4>
                        <ul>
                            <li><img src={icon} alt=""/>
                                <p>vykdyti labdaringą veiklą piliečių, visuomenės labui;</p>
                            </li>
                            <li><img src={icon} alt=""/>
                                <p>teikti pagalbą ir paramą Ukrainos ginkluotosioms pajėgoms ir kitoms teisėtoms Ukrainos gynybos formuotėms;</p>
                            </li>
                            <li><img src={icon} alt=""/>
                                <p>vykdyti labdaringą veiklą kultūros, mokslo, meno ir švietimo įstaigų organizavimo, kūrimo, paramos ir plėtros srityse;</p>
                            </li>
                            <li><img src={icon} alt=""/>
                                <p>sukurti erdvę vaikų ir jaunimo savirealizacijai;</p>
                            </li>
                            <li><img src={icon} alt=""/>
                                <p>skatinti sveiką gyvenseną;</p>
                            </li>
                            <li><img src={icon} alt=""/>
                                <p>teikti pagalbą našlaičiams, vaikams iš mažas pajamas gaunančių ir asocialių šeimų, vaikams su negalia;</p>
                            </li>
                            <li><img src={icon} alt=""/>
                                <p>teikti pagalbą ukrainiečiams, kurie yra priverstinėje emigracijoje;</p>
                            </li>
                            <li><img src={icon} alt=""/>
                                <p>finansuoti projektus, kuriais remiamos esamos ir kuriamos bei statomos naujos įstaigos, stadionai, žaidimų aikštelės, vaikų globos namai ir kt.;</p>
                            </li>
                            <li><img src={icon} alt=""/>
                                <p>įsigyti infrastruktūros elementus, statyti gyvenamuosius pastatus, įsigyti žemės sklypus ir kt. tiek Ukrainoje, tiek kitose valstybėse, siekiant apgyvendinti nukentėjusius nuo katastrofų, ginkluotų konfliktų ir nelaimingų atsitikimų;</p>
                            </li>
                            <li><img src={icon} alt=""/>
                                <p>finansuoti infrastruktūros ir gyvenamųjų pastatų, nukentėjusių nuo ginkluotų konfliktų, atkūrimo ir rekonstrukcijos projektus;</p>
                            </li>
                            <li><img src={icon} alt=""/>
                                <p>finansuoti socialinio būsto atkūrimą ir statybą;</p>
                            </li>
                            <li><img src={icon} alt=""/>
                                <p>finansuoti socialinių pastatų statybą.</p>
                            </li>
                        </ul>
                    </div>
                </div>
            </section>
            <section className={cn(classes.section, classes.sectionBGLight)}>
                <div className='container'>
                    <h4>Pagrindinės fondo labdaringos veiklos formos:</h4>
                    <ol>
                        <li>Vienkartinės finansinės, materialinės, organizacinės ir kitokios pagalbos teikimas (įskaitant dotacijų teikimą).</li>
                        <li>Sistemingos finansinės, materialinės, organizacinės ir kitokios pagalbos teikimas.</li>
                        <li>Konkrečių tikslinių programų finansavimas.</li>
                        <li>Pagalbos teikimas remiantis sudarytomis sutartimis (kontraktais) dėl labdaringos veiklos.</li>
                        <li>Dovanojimas arba leidimo nemokamam (privilegijuotam) turto naudojimui (naudojimuisi turtu) suteikimas.</li>
                        <li>Teikti pagalbą asmeniniu darbu, paslaugomis ar perduodant Fondo darbuotojų ar asmenų, kuriuos Fondas pritraukė pagal sutartį, asmeninės kūrybinės veiklos rezultatus įgijėjams.</li>
                        <li>Prisimti nemokamos, visiškos ar dalinės labdaros objektų priežiūros išlaidas.</li>
                        <li>Dalyvauti teikiant dotacijas, kitas labdaringas aukas ir finansinę pagalbą bendruomenės fondams, taip pat pagalbą teikiant tokią pagalbą bendruomenės fondams iš fizinių ir juridinių asmenų, tarptautinių organizacijų.</li>
                        <li>Filantropinės veiklos, informacijos, susijusios su Fondo tikslais ir uždaviniais, platinimas ir skleidimas.</li>
                        <li>Dalyvauti patariamųjų institucijų veikloje, viešose diskusijose, konsultacijose, nagrinėti norminius teisės aktus, taip pat stebėsenos ir vertinimo veikloje, susijusioje su Fondo tikslais ir uždaviniais.</li>
                        <li>Suteikti leidimą naudoti savo vardą, emblemą, simbolius.</li>
                        <li>Viešai rinkti labdaringas aukas.</li>
                        <li>Labdaros fondų valdymas.</li>
                        <li>Testamentų, atsisakymų sudaryti testamentą ir paveldėjimo sutarčių labdaringai veiklai vykdymas.</li>
                        <li>Labdaros aukcionų, nepiniginių loterijų, konkursų ir kitų įstatymų nedraudžiamų labdaros renginių rengimas.</li>
                    </ol>
                </div>
            </section>
        </main>
    )
}

export default HomePage
